import axios from "axios";

const axiosInstance = axios.create();

const httpConfig = (headers) => {
    return {
        baseURL: 'https://galpon-api.netlify.app/galpon/api/',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            ...headers
        }
    }
}

const client = {
    get: (url, headers = {}) => axiosInstance.get(url, httpConfig(headers)),
    post: (url, data, headers = {}) => axiosInstance.post(url, data, httpConfig(headers)),
    put: (url, data, headers = {}) => axiosInstance.put(url, data, httpConfig(headers)),
}

export default client;