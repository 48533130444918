import "./Products.scss";
import Product from "../../components/product/Product";
import Cheese from "../../assets/images/cheese.png";
import ColdCuts from "../../assets/images/coldCuts.png";
import Frozen from "../../assets/images/frozen.png";
import Dressings from "../../assets/images/dressings.png";
import Potatos from "../../assets/images/potatos.png";
import Olives from "../../assets/images/olives.png";

const Products = () => {
    return (
        <section className="products-section" id="productos">
            <h3 className="products-title">Productos</h3>
            <p className="products-text">Elaboramos y procesamos cada uno de nuestros productos para brindar <strong>calidad y sabor</strong>, convirtiéndonos en la <strong>elección ideal para alcanzar el éxito en tu negocio gastronómico:</strong> productos listos para su consumo, stock constante, sin desperdicio, ahorro de tiempo y personal, mejor control de costos.</p>
            <div className="products-container">
                <Product 
                    title={'Alimentos 100% naturales'} 
                    description={'Papa, batata, cebolla, calabaza, zanahoria, entre otros.'} 
                    image={Potatos} />
                <Product 
                    title={'Congelados'} 
                    description={'Nuggets, medallones, papas, tequeños, burritos, vegetales, entre otros.'} 
                    image={Frozen} />
                <Product 
                    title={'Fiambres'} 
                    description={'Paleta, panceta, jamón, bondiola, lomo, salame, ¡y más!'} 
                    image={ColdCuts} />
                <Product 
                    title={'Quesos'} 
                    description={'Diferentes variedades de queso en barra, duros y blandos.'} 
                    image={Cheese} />
                <Product 
                    title={'Aderezos y salsas'}
                    description={'Mayonesa, ketchup, mostaza, BBQ, Teriyaqui, Caesar, entre otros.'} 
                    image={Dressings} />
                <Product    
                    title={'Otros'}
                    description={'Contamos con una amplia variedad de productos, ¡conocelos todos!'} 
                    image={Olives} />
            </div>
        </section>
    )
}

export default Products;