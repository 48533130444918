import Header from "../../parts/header/Header";
import Services from "../../parts/services/Services";
import ListOfProducts from "../../parts/listOfProducts/ListOfProducts";
import Products from "../../parts/products/Products";
import Brands from "../../parts/brands/Brands";
import Contact from "../../parts/contact/Contact";
import Footer from "../../parts/footer/Footer";
import WhatsappButton from "../../components/whatsappButton/WhatsappButton";
import { getContactData } from "../../shared/services/contactData";
import { useEffect, useState } from "react";
import './Landing.scss';
import Vision from "../../parts/vision/Vision";

const Landing = () => {
    const [data, setData] = useState({})

    useEffect(() => {
        (async () => {
            const { data: contactData } = await getContactData();
            setData(contactData);
        })();
    }, [])

    return (
        <div className="landing-container">
            <Header drive={data.drive}/>
            <Vision/>
            <Services/>
            <ListOfProducts drive={data.drive}/>
            <Products/>
            <Brands/>
            <Contact/>
            <Footer instagram={data.instagram}/>
            <WhatsappButton number={data.whatsapp}/>
        </div>
    )
}

export default Landing;