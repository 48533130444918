import './Vision.scss';

const Vision = () => {
    return (
        <section className="vision-section">
            <h3 className="vision-title">Sobre nosotros</h3>
            <span className="vision-description">Nuestra misión es <strong>facilitarles a nuestros clientes los procesos de elaboración en sus cocinas.</strong> Por eso, nos especializamos en la producción y procesado de hortalizas y vegetales 100% naturales con el fin de brindarles mayor comodidad y rendimiento en sus comercios.</span>
        </section>
    )
}

export default Vision;