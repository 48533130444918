import { useEffect, useState } from 'react';
import Modal from '../../components/modal/Modal';
import Button from '../../components/button/Button';
import Input from '../../components/input/Input';
import { usePassword } from '../../shared/hooks/usePassword';
import './NewPassword.scss';
import { useNavigate } from 'react-router-dom';

const NewPassword = () => {
    const { passwords, hiddenPassword, newPasswords, setNewPasswords, showButton, handlerPassword } = usePassword();
    const [ result, setResult ] = useState({ showModal: false, title: '', description: '', messageError: null });
    const navigate = useNavigate();

    useEffect(() => {
        setResult({ showModal: false, title: '', description: '', messageError: null })
    }, [newPasswords])

    const handler = async (e) => {
        e.preventDefault();
        const result = await handlerPassword();
        setResult(result);
    }

    const handlerModal = (e) => {
        e.preventDefault();
        navigate('/login')
    }
 
    return (
        <div className='new-password-container'>
            { result.showModal && <Modal 
            title={result?.title} 
            description={result?.description}
            handler={(e) => handlerModal(e)}
            ></Modal>}
            <h1 className='new-password-title'>Nueva contraseña</h1>
            <span className='new-password-description'>Ingresá dos veces la nueva contraseña que vas a utilizar para iniciar sesión.</span>
            <Input placeholder={'Contraseña'} 
            icon={passwords.newPassword.icon} 
            value={newPasswords.newPassword} 
            type={passwords.newPassword.type}
            handler={(e) => setNewPasswords({ ...newPasswords, newPassword: e.target.value })}
            handlerIcon={(e) => hiddenPassword(e, true)}></Input>
            <ins className='new-password-suggestion'>*la contraseña debe contener al menos 9 dígitos, una letra mayúscula, un número y un carácter especial !@#$%^&*()</ins>
            <Input placeholder={'Confirmar contraseña'} 
            icon={passwords.confirmPassword.icon} 
            value={newPasswords.confirmPassword} 
            type={passwords.confirmPassword.type}
            handler={(e) => setNewPasswords({ ...newPasswords, confirmPassword: e.target.value })}
            handlerIcon={(e) => hiddenPassword(e, false)}
            error={result.messageError}></Input>
            <Button text={'Confirmar cambio de contraseña'} disabled={!showButton()} handler={async (e) => await handler(e)}></Button>
        </div>
    )
}

export default NewPassword;