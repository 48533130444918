import Button from "../button/Button";
import ReactDOM from 'react-dom';
import './Modal.scss';

const Modal = ({ handler, title, description }) => {
    return ReactDOM.createPortal(
            <>
            <div className="overlay-modal"/>
            <div className="modal-container">
              <h2 className="modal-title">{title}</h2>
              <span className="modal-description">{description}</span>
              <Button text={'Aceptar'} handler={handler}></Button>
            </div>
            </>
            , document.getElementById('modal'))
}

export default Modal;