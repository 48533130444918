import { useEffect, useState } from "react"
import { getContactData, updateContactData } from "../../shared/services/contactData";
import { useNavigate } from "react-router-dom";

export const useAdmin = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({
        email: "",
        whatsapp: "",
        instagram: "",
        drive: ""
    });

    useEffect(() => {
        (async () => {
            const { data: contactData } = await getContactData();
            setForm(contactData);
        })();
    }, [])

    const showButton = () => {
        return form.drive.length && form.email.length && form.whatsapp.length && form.instagram.length
    }

    const saveChanges = async () => {
        try {
            await updateContactData(form);
            
            return { showModal: true, emailError: null, whatsappError: null, genericError: null }
        } catch ({ response }) {
            if (response.status === 401) {
                navigate('/login')
                return;
            }
            
            const eError = response.data.includes("No contiene formato del email") ? "No contiene formato del email" : null;
            const wError = response.data.includes("No contiene formato de whatsapp") ? "No contiene formato del whatsapp" : null;
            return { showModal: false, emailError: eError, whatsappError: wError, genericError: response.status === 500 ? 'Hubo un problema, vuelva a intentar' : null }
        }
    }

    return { form, setForm, showButton, saveChanges }
}