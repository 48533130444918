import ReactDOM from 'react-dom';
import Landing from './pages/landing/Landing';
import Navbar from './parts/navbar/Navbar';
import { useState } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Admin from './pages/admin/Admin';
import Login from './pages/login/Login';
import NewPassword from './pages/newPassword/NewPassword';
import NotFound from './pages/notFound/NotFound';
import { useIsMobile } from './shared/hooks/useIsMobile';
import './App.scss';


const App = () => {
  const [portal, setPortal] = useState(false)
  const isMobile = useIsMobile();

  const handlerPortal = (portal) => {
    return portal ? ReactDOM.createPortal(
      <>
      <div className="overlay-modal"/>
      <div className="navbar-modal-container">
        <Navbar handlerMenu={setPortal} isOpen={portal} />
      </div>
      </>
      , document.getElementById('navbar')) : <Navbar handlerMenu={setPortal} isOpen={portal}/>
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<NotFound />}>
          <Route path="/" element={<Landing />} />
          {!isMobile &&
          <><Route path="login" element={<Login />} /><Route path="password" element={<NewPassword />} /><Route path="admin" element={<Admin />} /></>
          }
      </Route>
    )
  );

  return (
    <div className='App'>
      {handlerPortal(portal)}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
