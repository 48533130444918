import './Brands.scss';
import McCain from '../../assets/images/mcCain.png';
import Hellmans from '../../assets/images/hellmans.png';
import TAU from '../../assets/images/TAU.png';
import Tonadita from '../../assets/images/tonadita.png';
import Paty from '../../assets/images/paty.png';
import Milkaut from '../../assets/images/milkaut.png';
import Danica from '../../assets/images/danica.png';
import Simplot from '../../assets/images/simplot.png';
import Luvianka from '../../assets/images/luvianka.png';
import Grangys from '../../assets/images/grangys.png';

const Brands = () => {
    return (
        <section className='brands-section' id='brands'>
            <h3 className='brands-title'>Marcas que comercializamos</h3>
            <figure className='brands-container'>
                <img src={McCain} alt='McCain'></img>
                <img src={Hellmans} alt='Hellmans'></img>
                <img src={TAU} alt='TAU'></img>
                <img src={Tonadita} alt='Tonadita'></img>
                <img src={Paty} alt='Paty'></img>
                <img src={Milkaut} alt='Milkaut'></img>
                <img src={Danica} alt='Danica'></img>
                <img src={Simplot} alt='Simplot'></img>
                <img src={Luvianka} alt='Luvianka'></img>
                <img src={Grangys} alt='Grangys'></img>
            </figure>
        </section>
    )
}

export default Brands;