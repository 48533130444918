import EyePass from '../../assets/icons/eyePass.svg';
import EyeNoPass from '../../assets/icons/eyeNoPass.svg';
import { restorePassword, loginApi } from "../../shared/services/login";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

export const useLogin = () => {
    const [password, setPassword] = useState({
        hasShow: false,
        icon: EyePass,
        type: 'password'
    });
    const [login, setLogin] = useState({
        email: '',
        password: '',
    })
    const navigate = useNavigate();

    const showButton = () => {
        return login.email.length && login.password.length > 8;
    }

    const showPassword = (e) => {
        e.preventDefault();
        setPassword({
            hasShow: !password.hasShow,
            icon: password.hasShow ? EyeNoPass : EyePass,
            type: password.hasShow ? 'text' : 'password',
        })
    }

    const restore = async (e, setSendRestore) => {
        e.preventDefault();
        try {
            await restorePassword();
            setSendRestore({
                title: "REESTABLECER CONTRASEÑA",
                description: "Te enviamos un mail a tu correo electrónico con los pasos a seguir para cambiar tu contraseña."
            })
        } catch (error) {
            setSendRestore({
                title: "Hubo un error",
                description: "Por favor, volver a intentar"
            })
        }
    }

    const handlerLogin = async () => {
        try {
            const { data } = await loginApi(login);

            if (data.isValid) {
                navigate('/admin')
                localStorage.setItem('token', data.token)
            } else {
                return {
                    messageError: 'La contraseña o el email es incorrecto'
                }
            }
        } catch ({ response }) {
            return {
                messageError: response.status === 400 ? 'La contraseña o el email es incorrecto' : 'Hubo un problema, vuelva a intentar'
            }
        }
    }

    return { setLogin, login, password, showPassword, restore, showButton, handlerLogin }

}

