import { sendContact } from "../../shared/services/contactData";
import { useState } from "react";

export const useContact = () => {
    const [form, setForm] = useState({
        name: '',
        phone: '',
        email: ''
    })

    const showButton = () => {
        return form.name.length && form.phone.length && form.email.length;
    }

    const sendEmail = async () => {
        try {
            await sendContact(form)
            setForm({
                name: '',
                phone: '',
                email: ''
            })
            return null
        } catch ({response}) {
            return response.status === 400 ? response.data[0] : 'Hubo un problema, vuelva a intentar';
        }
    }

    return { form, setForm, showButton, sendEmail }
}