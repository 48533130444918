import "./Service.scss";
import Line from "../../assets/icons/line.svg";

const Service = ({icon, title, description}) => {
    return (
        <div className="service-container">
            <img src={icon} alt={title} className='service-image'></img>
            <h4 className="service-title">{title}</h4>
            <img src={Line} alt="line"  className='service-image'></img>
            <p className="service-description">{description}</p>
        </div>
    )
}

export default Service;