import Service from "../../components/service/Service";
import Attention from "../../assets/icons/attention.svg";
import Products from "../../assets/icons/products.svg";
import Team from "../../assets/icons/team.svg";
import Deliveries from "../../assets/icons/deliveries.svg";
import "./Services.scss";

const Services = () => {
    return (
        <section className="services-section" id="servicios">
            <Service 
                key={'attention'}
                title={'Atención'} 
                icon={Attention}
                description={["Contamos con un servicio de atención y logística personalizado de lunes a sábado. Aceptamos consultas a través de ", <strong key={'strong-attetion'}> correo electrónico, Whatsapp e Instagram.</strong>]} />
            <Service 
                key={'deliveries'}
                title={'Entregas'} 
                icon={Products} 
                description={["Contamos con camionetas especializadas en el transporte de productos en CABA y GBA, lo que nos permite asegurar que ", <strong key={'strong-deliveries'}>tus pedidos llegarán puntualmente y en perfectas condiciones.</strong>]} />
            <Service 
                key={'products'}
                title={'Productos'} 
                icon={Deliveries} 
                description={["Nos destacamos por la ", <strong key={'strong-products'}>calidad de los productos que comercializamos.</strong>, " Trabajamos para seleccionar cuidadosamente cada producto que procesamos."]} />
            <Service 
                key={'team'}
                title={'Equipo'} 
                icon={Team} 
                description={["Nuestro equipo está capacitado para brindar ayuda en cualquier momento. Recibirás un ", <strong key={'strong-products'}>trato amable, eficiente y dedicado.</strong>]} />
        </section>
    )
}

export default Services;