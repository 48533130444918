import './Contact.scss';
import Button from '../../components/button/Button';
import Input from '../../components/input/Input';
import Send from '../../assets/icons/send.svg';
import { useContact } from '../../shared/hooks/useContact';
import { useEffect, useState } from 'react';

const Contact = () => {
    const { form, setForm, showButton, sendEmail  } = useContact();
    const [error, setError] = useState(null);

    useEffect(() => {
        setError(null)
    }, [form])

    const handler = async (e) => {
        e.preventDefault();
        const error = await sendEmail();
        setError(error);
    }

    return (
        <section className="contact-section" id='contacto'>
            <div className='contact-text-container'>
                <h3 className='contact-title'>Contactanos</h3>
                <em className='text'>Dejanos tu mensaje a través de este formulario. Estamos ansiosos por hablar con vos y responder a tus inquietudes en el menor tiempo posible, ¡gracias!</em>
            </div>
            <form className='contact-form'>
                <Input placeholder='Nombre y apellido / Empresa' value={form.name} handler={(e) => setForm({ ...form, name: e.target.value})}></Input>
                <Input placeholder='Teléfono' value={form.phone} handler={(e) => setForm({ ...form, phone: e.target.value})}></Input>
                <Input placeholder='Email' value={form.email} handler={(e) => setForm({ ...form, email: e.target.value})} error={error}></Input>
                <Button text={'Enviar'} icon={Send} disabled={!showButton()} handler={(e) => handler(e)}></Button>
            </form>
        </section>
    )
}

export default Contact;