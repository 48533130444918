import Input from '../input/Input.js';
import Edit from '../../assets/icons/edit.svg';
import './DataInput.scss';

const DataInput = ({title, description, error, value, handlerInput}) => {
    return (
        <div className='data-input-container'>
            <Input value={value} handler={handlerInput} error={error} title={title} placeholder={title} icon={Edit}></Input>
            <hr></hr>
            <p className='data-input-description'>{description}</p>
        </div>
    )
}

export default DataInput;