import Button from '../../components/button/Button';
import Download from '../../assets/icons/download.svg'
import Food from "../../assets/images/food.png"
import './Header.scss';

const Header = ({ drive }) => {
    const download = () => {
        window.open(drive, "_blank");
        
    }

    return (
        <header className='header-landing'>
            <div className='header-description-container'>
                <h1 className='header-title'>Todo para potenciar tu negocio gastronómico</h1>
                <span className='header-description'>Si estás en busca de un proveedor de alimentos procesados 100% naturales para tu restaurante, no dudes en conocer nuestras opciones. Descubrí la <strong>calidad y versatilidad que ofrecen nuestros productos.</strong></span>
                <Button text={'Descargar lista de precios'} icon={Download} handler={() => download()}></Button>
            </div>
            <img src={Food} alt={'Papas'} className="header-image"></img>
        </header>
    )
}

export default Header;