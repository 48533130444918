import Button from '../../components/button/Button';
import Download from '../../assets/icons/download.svg'
import './ListOfProducts.scss';

const ListOfProducts = ({ drive }) => {
    const download = () => {
        window.open(drive, "_blank");
        
    }

    return (
        <section className="list-of-products-section" id='lista-de-precios'>
            <div className='list-of-products-container'>
                <h3 className='list-of-products-title'>Descargá nuestra lista de productos</h3>
                <span className='list-of-products-description'>Descubrí las ofertas que tenemos para vos. Encontrá toda la variedad de productos con precio actualizado.</span>
                <div className='list-of-products-button'>
                    <Button text={'Descargar lista de precios'} icon={Download} handler={() => download()}></Button>
                </div>
            </div>
        </section>
    )
}

export default ListOfProducts;