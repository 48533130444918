import './Footer.scss'
import Logo from "../../assets/icons/logoWhite.svg";
import Instagram from "../../assets/icons/instagram.svg";
import { Link } from 'react-router-dom';

const Footer = ({ instagram }) => {
    const year = new Date().getFullYear();

    const openInstagram = () => {
        window.open("https://www.instagram.com/distribuidora.elgalpon/?hl=es#", "_blank");
    }

    return (
        <footer>
            <figure>
                <img src={Logo} alt="Logo"></img>
                <div className='icon-container' onClick={() => openInstagram()}>
                    <img src={Instagram} alt="Instagram" title="Instragram"></img>
                </div>
            </figure>
            <div className='link-container'>
                <Link to='/' reloadDocument={true} className='link'>Inicio</Link>
                <Link to='/#servicios' reloadDocument={true} className='link'>Nosotros</Link>
                <Link to='/#productos' reloadDocument={true} className='link'>Productos</Link>
                <Link to='/#lista-de-precios' reloadDocument={true} className='link'>Lista de precios</Link>
                <Link to='/#contacto' reloadDocument={true} className='link'>Contacto</Link>
            </div>
            <i>{`© ${year} El Galpón. Todos los derechos reservados`}</i>
        </footer>
    )
}

export default Footer;