import EyePass from '../../assets/icons/eyePass.svg';
import EyeNoPass from '../../assets/icons/eyeNoPass.svg';
import { useState } from 'react';
import { newPassword } from '../../shared/services/login';
import { useLocation, useNavigate } from "react-router-dom";

const ERRORS = {
    400: 'La contraseña no cumple los requisitos',
    401: 'Se acabo el tiempo para cambiar la contraseña',
    409: 'Esta contraseña ya fue usada',
    500: 'Hubo un problema, vuelva a intentar'
}

export const usePassword = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const [passwords, setPasswords] = useState({
        newPassword: {
            hasShow: false,
            icon: EyePass,
            type: 'password'
        },
        confirmPassword: {
            hasShow: false,
            icon: EyePass,
            type: 'password'
        }
    });

    const [newPasswords, setNewPasswords] = useState({
        newPassword: '',
        confirmPassword: '',
    })

    const hiddenPassword = (e, isNewPassword) => {
        e.preventDefault();
        if (isNewPassword) {
            setPasswords({
                ...passwords,
                newPassword: {
                    hasShow: !passwords.newPassword.hasShow,
                    icon: passwords.newPassword.hasShow ? EyeNoPass : EyePass,
                    type: passwords.newPassword.hasShow ? 'text' : 'password',
                }
            })
        } else {
            setPasswords({
                ...passwords,
                confirmPassword: {
                    hasShow: !passwords.confirmPassword.hasShow,
                    icon: passwords.confirmPassword.hasShow ? EyeNoPass : EyePass,
                    type: passwords.confirmPassword.hasShow ? 'text' : 'password',
                }
            })
        }
    }

    const toBeEqualPassword = () => {
        return newPasswords.newPassword === newPasswords.confirmPassword;
    }

    const showButton = () => {
        return newPasswords.confirmPassword.length > 9 && newPasswords.newPassword.length > 9;
    }

    const handlerPassword = async () => {
        if (!toBeEqualPassword()) {
            return { showModal: false, title: '', description: '', messageError: "Las contraseñas no son iguales" }
        }

        try {
            const token = new URLSearchParams(search).get('token');

            if (!token) {
                navigate('/login');
                return;
            }

            await newPassword({ newPassword: newPasswords.newPassword }, token);
            
            return { showModal: true, title: 'Contraseña generada con éxito', description: 'Ya podés volver a iniciar sesión con las nuevas credenciales.', messageError: null }
        } catch ({ response }) {
            const error = ERRORS[response.status] 

            return { showModal: false, title: '', description: '', messageError: error || 'Hubo un problema, vuelva a intentar' }
        }
    }

    return { passwords, hiddenPassword, setNewPasswords, newPasswords, showButton, handlerPassword }
}