import './Product.scss';

const Product = ({title, description, image}) => {
    return (
        <div className='product-container'>
            <div className="product-description-container">
                <h4 className="product-title">{title}</h4>
                <p className="product-description">{description}</p>
            </div>
            <img src={image} alt={title} className="product-image"/>
        </div>
    )
}

export default Product;