import client from "./api";

export const getContactData = async () => {
    return await client.get('/contact')
}

export const updateContactData = async (data) => {
    const token = localStorage.getItem('token');

    await client.put('/contact', data, { 'Authorization': token })
}

export const sendContact = async (data) => {
    return await client.post('/contact/send', data)
}