import './WhatsappButton.scss';
import whatsapp from '../../assets/icons/whatsapp.svg';

const WhatsappButton = ({ number }) => {
    const handlerWp = () => {
        window.location.href = `https://wa.me/${number}`
    }

    return (
        <button className='whatsapp-button' onClick={handlerWp}>
            <img src={whatsapp} alt="Whatsapp"></img>
        </button>
    )
}

export default WhatsappButton;