import Button from "../../components/button/Button"
import DataInput from "../../components/dataInput/DataInput";
import Modal from '../../components/modal/Modal';
import { Link } from "react-router-dom";
import { useAdmin } from "../../shared/hooks/useAdmin";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './Admin.scss';

const Admin = () => {
    const { form, setForm, showButton, saveChanges } = useAdmin();
    const [ result, setResult ] = useState({ showModal: false, emailError: null, whatsappError: null, genericError: null });
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    useEffect(() => {
        setResult({ showModal: false, emailError: null, whatsappError: null, genericError: null })
    }, [form])

    if (!token) {
        navigate('/login')
    }

    const handler = async (e) => {
        e.preventDefault();
        const result = await saveChanges();
        setResult(result);
    }

    const handlerModal = (e) => {
        e.preventDefault();
        setResult({ showModal: false, emailError: null, whatsappError: null, genericError: null })
    }

    return (
        <div className="admin-container">
            { result.showModal && <Modal 
            title={'Cambios guardados con éxito'} 
            description={'Por favor verificar en la página si todo funciona bien.'}
            handler={(e) => handlerModal(e)}
            ></Modal>}
            <header className="admin-header">
                <h2 className="admin-title">Administración del sitio</h2>
                <Link to={'/login'} className="admin-logout">Cerrar sesión</Link>
            </header>
            <section className="settings-container">
                <div className="settings-header">
                    <h3 className="settings-title">Configuración</h3>
                    <Button text={'Guardar cambios'} disabled={!showButton()} handler={(e) => handler(e)}></Button>
                </div>
                <DataInput
                    title={'Email'}
                    value={form.email}
                    description={'Dirección de correo electrónico donde se van a recibir los mensajes del formulario de contacto.'}
                    handlerInput={(e) => setForm({ ...form, email: e.target.value})}
                    error={result.emailError}
                />
                <DataInput
                    title={'Número de contacto'}
                    value={form.whatsapp}
                    description={'Número de WhatsApp que se va a utilizar en el botón de contacto de la app. Colocar código de área, sin caracteres especiales.'}
                    handlerInput={(e) => setForm({ ...form, whatsapp: e.target.value})}
                    error={result.whatsappError}
                />
                <DataInput
                    title={'Instagram'}
                    value={form.instagram}
                    description={'Usuario de Instagram al que va a redirigir el enlace correspondiente. Introducir URL del perfil.'}
                    handlerInput={(e) => setForm({ ...form, instagram: e.target.value})}
                />
                <DataInput
                    title={'Lista de precios'}
                    value={form.drive}
                    description={'Link que va a redirigir a la lista de precios. Introducir URL del PDF/Drive.'}
                    handlerInput={(e) => setForm({ ...form, drive: e.target.value})}
                    error={result.genericError}
                />
            </section>
        </div>
    )
}

export default Admin;