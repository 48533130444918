import client from "./api";

export const restorePassword = async () => {
    return await client.post('/customer/password', {})
}

export const loginApi = async (data) => {
    return await client.post('/customer/login', data)
}

export const newPassword = async (data, token) => {
    await client.put('/customer/password', data, { 'Authorization': token })

    localStorage.clear();
}