import { useEffect, useState } from 'react';
import Modal from '../../components/modal/Modal';
import Button from '../../components/button/Button';
import Input from '../../components/input/Input';
import { useLogin } from '../../shared/hooks/useLogin';
import './Login.scss';

const Login = () => {
    const { setLogin, login, showPassword, restore, showButton, password, handlerLogin } = useLogin();
    const [ error, setError ] = useState({ messageError: null})
    const [ sendRestore, setSendRestore ] = useState(null)

    useEffect(() => {
        localStorage.clear();
    }, [])

    useEffect(() => {
        setError({ messageError: null})
    }, [login])

    const handler = async (e) => {
        e.preventDefault();
        const error = await handlerLogin();
        !!error?.messageError && setError(error);
    }

    const handlerModal = (e) => {
        e.preventDefault();
        setSendRestore(null)
    }

    return (
        <div className='login-container'>
            { !!sendRestore && <Modal 
            title={sendRestore?.title} 
            description={sendRestore?.description}
            handler={(e) => handlerModal(e)}
            ></Modal>}
            <h1 className='login-title'>Bienvenido</h1>
            <span className='login-description'>Para iniciar sesión como admin, escribí tu usuario (email) y contraseña.</span>
            <Input placeholder={'Email'} handler={(e) => setLogin({ ...login, email: e.target.value })}></Input>
            <Input placeholder={'Contraseña'} icon={password.icon} 
            handlerIcon={(e) => showPassword(e)} type={password.type} 
            handler={(e) => setLogin({ ...login, password: e.target.value })}
            error={error.messageError}></Input>
            <button className='login-new-password-link' onClick={(e) => restore(e, setSendRestore)}>¿Olvidaste tu contraseña? Reestablecela acá</button>
            <Button text={'Iniciar sesión'} disabled={!showButton()} handler={async (e) => await handler(e)}></Button>
        </div>
    )
}

export default Login;