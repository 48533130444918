import './Button.scss';

const Button = ({text, handler, icon, disabled}) => {
    return (
        <button onClick={handler} disabled={disabled} className={'primary-button'}>
            {text}
            {icon && <img src={icon} alt={'icon'}/>}
        </button>
    )   
}

export default Button;