import Button from '../../components/button/Button';
import { useNavigate } from "react-router-dom";
import './NotFound.scss';

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className='not-found-container'>
            <h1 className='not-found-title'>UPS... ALGO SALIÓ MAL</h1>
            <span className='not-found-description'>Buscamos por todos lados, pero no encontramos esta página. Vamos a llevarte a un mejor lugar.</span>
            <Button text={'Volver al inicio'} handler={() => navigate('/')}></Button>
        </div>
    )
}

export default NotFound;