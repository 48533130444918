import './Input.scss';

const Input = ({title, placeholder, handler, value, error = null, icon = null, handlerIcon = null, type = 'text'}) => (
    <div className='input-container'>
        { !!title && <span className='input-title'>{title}</span>}
        <div className='input'>
            <input placeholder={placeholder} value={value} onChange={(e) => handler(e)} type={type} 
            style={{ textOverflow: 'ellipsis', paddingRight: 55}}></input>
            {!!icon && <img src={icon} className="icon-input" alt="icon" onClick={(e) => handlerIcon(e)}></img>}
        </div>
        { !!error && <ins className='input-text-validate'>{error}</ins>}
    </div>
)

export default Input;