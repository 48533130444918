import { useIsMobile } from '../../shared/hooks/useIsMobile.js';
import Button from '../../components/button/Button';
import Logo from '../../assets/icons/logo.svg';
import Menu from '../../assets/icons/menu.svg';
import Close from '../../assets/icons/close.svg';
import './Navbar.scss';

const Navbar = ({ handlerMenu, isOpen }) => {
    const isMobile = useIsMobile();

    return (
        <nav>
            <div className='navbar-container'>
                <div className='navbar-inside'>
                    <img src={Logo} alt='El Galpón Distribuidora'></img>
                    <div className='navbar-links-container'>
                        {
                            !isMobile ?
                                <>
                                    <a href='/' className='navbar-link'>{'Inicio'}</a>
                                    <a href='/#servicios' className='navbar-link'>{'Servicios'}</a>
                                    <a href='/#productos' className='navbar-link'>{'Productos'}</a>
                                    <a href='/#lista-de-precios' className='navbar-link'>{'Lista de precios'}</a>
                                    <Button text={'Contactanos'} handler={() => { window.location.href = '/#contacto'}}></Button>
                                </>
                                :
                                (
                                    isOpen ?
                                        <img src={Close} alt="Close" onClick={() => handlerMenu(!isOpen)}></img>
                                        :
                                        <img src={Menu} alt="Menu" onClick={() => handlerMenu(!isOpen)}></img>
                                )
                        }
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className='navbar-menu'>
                    <a href='/' className='navbar-link' onClick={() => handlerMenu(false)}>{'Inicio'}</a>
                    <a href='/#servicios' className='navbar-link' onClick={() => handlerMenu(false)}>{'Servicios'}</a>
                    <a href='/#productos' className='navbar-link' onClick={() => handlerMenu(false)}>{'Productos'}</a>
                    <a href='/#lista-de-precios' className='navbar-link' onClick={() => handlerMenu(false)}>{'Lista de precios'}</a>
                    <Button text={'Contactanos'}  handler={() => { window.location.href = '/#contacto';  handlerMenu(false)}}></Button>
                </div>
            )}
        </nav>
    )
}

export default Navbar;